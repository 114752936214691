var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "express" }, [
    _c("div", { staticClass: "bg" }),
    _c("div", { staticClass: "wrap" }, [
      _c("div", { staticClass: "info e-d" }, [
        _c("div", { staticClass: "wrap flex f-jc-sb" }, [
          _c("span", [_vm._v("运单号")]),
          _c("span", [_vm._v(_vm._s(_vm.logisticsOrderNo))]),
        ]),
        _c("div", { staticClass: "wrap flex f-jc-sb" }, [
          _c("span", [_vm._v("国内承运人")]),
          _c("span", [_vm._v(_vm._s(_vm.logisticsName))]),
        ]),
      ]),
      _c("div", { staticClass: "detail e-d" }, [
        _c(
          "ul",
          { staticClass: "wrap" },
          _vm._l(_vm.expressList, function (item, index) {
            return _c(
              "li",
              {
                key: index,
                staticClass: "flex f-d-c",
                class: [
                  !index ? `active-${item.statusNum}` : `g-${item.statusNum}`,
                  index === _vm.expressList.length - 1 ? "no-border" : "",
                ],
              },
              [
                _c("p", [_vm._v(_vm._s(item.status))]),
                _vm._l(item.list, function (ite, ind) {
                  return _c("div", { key: ind, staticClass: "flex f-d-c" }, [
                    _c("span", [_vm._v(_vm._s(ite.context))]),
                    _c("span", [_vm._v(_vm._s(ite.time))]),
                  ])
                }),
              ],
              2
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }